"use client";
import { useEffect } from 'react';
import Intercom from '@intercom/messenger-js-sdk';
import { useSession } from 'next-auth/react';

export default () => {
    const { data: session } = useSession()
    
    const getIntercomHash = async (userId: string) => {
        try {
            const response = await fetch('/api/auth/intercom', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ userId }),
            });

            if (!response.ok) {
                throw new Error('Failed to get Intercom hash');
            }

            const { hash } = await response.json();
            return hash;
        } catch (error) {
            console.error('Intercom hash error:', error);
            return null;
        }
    }

    useEffect(() => {
        (async () => {
            const userId = session?.user?.id;
            
            const intercomConfig: any = {
                app_id: process.env.NEXT_PUBLIC_INTERCOM_APP_ID ?? '',
            };
            if (userId) {
                const hash = await getIntercomHash(userId);
                
                if (hash) {
                    Object.assign(intercomConfig, {
                        user_id: userId,
                        name: session?.user?.name ?? '',
                        email: session?.user?.email ?? '',
                        user_hash: hash,
                    });
                }
            }
            Intercom(intercomConfig);
        })();
    }, [session]);

    return null;
}