"use client";
import * as React from "react";
import Link from "next/link";
import { usePathname } from "next/navigation";
import { cn } from "@lib/utils";
import useIsMobile from "@hooks/useIsMobile";

interface NavLinkProps {
  children: React.ReactNode;
  href: string;
  className?: string;
  onClick?: () => void;
}

const NavLink = ({ children, className, href, onClick }: NavLinkProps) => {
  const pathname = usePathname();
  const isMobile = useIsMobile();

  const isCurrentPage = React.useMemo(() => pathname === href, [pathname, href]);

  return (
    <Link
      href={href}
      onClick={onClick}
      className={cn(
        "text-white inline-flex flex-col group/navlink pt-0.5 items-center whitespace-nowrap justify-center text-xl md:text-2xl lg:text-[15px] xl:text-base p-1 hover:text-opacity-70",
        isCurrentPage && !isMobile ? "text-primary font-medium xl:font-normal" : "",
        className
      )}
    >
      <span className="py-2">{children}</span>
      {!isMobile && (
        <span
          className={cn(
            "inline-block h-0.5 w-0 group-hover/navlink:w-full self-start transition-all duration-200 bg-primary",
            isCurrentPage ? "w-full" : ""
          )}
        />
      )}
    </Link>
  );
};

export default NavLink;
