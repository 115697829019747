"use client";
import React, { useEffect, useMemo, useState } from "react";
import { FiArrowRightCircle } from "react-icons/fi";
import { usePathname } from "next/navigation";
import { signOut, useSession } from "next-auth/react";
import { useIntl } from "react-intl";
import { Session } from "next-auth";
import Link from "next/link";
import Image from "next/image";
import { MdLogout } from "react-icons/md";
import { cn } from "@lib/utils";
import Logo from "@assets/images/logo.svg";
import { IoMdClose, IoMdMenu } from "react-icons/io";
import { Button } from "../../button";
import NavLink from "./NavLink";
import Avatar from "./Avatar";
import { endUserSession, getUserActiveStatus } from "@queries/session";
import ChatNotification from "./ChatNotification";
import { Plan, Subscription } from "@prisma/client";
import {
  ChevronDownIcon,
  ExclamationTriangleIcon,
} from "@radix-ui/react-icons";
import useIsMobile from "@hooks/useIsMobile";
import { useUserOnboardingStore } from "@store/onboarding";
import {
  ChevronRightIcon,
  GlobeAltIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import NavSearch from "./NavSearch";
import { Bars3BottomRightIcon as RightBarsIcon } from "@heroicons/react/24/outline";

const HeaderClient = ({
  initialSession,
  subscription,
  plan,
}: {
  initialSession: Session | null;
  subscription: Subscription | null;
  plan: Plan | null;
}) => {
  const { data, status } = useSession();
  const pathname = usePathname();
  const intl = useIntl();
  const isMobile = useIsMobile();
  const { user } = useUserOnboardingStore();
  const [showSubNav, setShowSubNav] = useState(false);

  const toggleSubNav = () => {
    setShowSubNav((prev) => !prev);
  };

  const [session, setSession] = useState<Session | null>(initialSession);

  const [isHeaderTransparent, setIsHeaderTransparent] = useState(
    pathname === "/" ? true : false
  );

  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    const getUserInfo = async () => {
      if (session) {
        const isActive = await getUserActiveStatus(session);
        if (!isActive) {
          await endUserSession(session);
        }
      }
    };

    getUserInfo();
    if (status !== "loading") {
      setSession(data);
    }
  }, [session, status, data]);

  useEffect(() => {
    const handleScroll = () => {
      const featuredCategoriesSection = document.getElementById(
        "featured-categories"
      );
      if (window.scrollY + 90 > (featuredCategoriesSection?.offsetTop || 0)) {
        setIsHeaderTransparent(false);
      } else {
        setIsHeaderTransparent(pathname === "/");
      }
    };

    handleScroll();
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [pathname]);

  const headerTranslation: any = intl.messages.header;
  const aboutTranslation: any = intl.messages.about;
  const contactTranslation: any = intl.messages.contact;

  const EMPLOYER_NAV_ITEMS = useMemo(
    () => [
      {
        title: headerTranslation["candidate-applications"],
        href: "/employer/profile/applications",
      },
      {
        title: headerTranslation["post-job"],
        href: "/employer/profile/jobs/post",
      },
      {
        title: "Companies pricing",
        href: "/pricing",
      },
    ],
    [headerTranslation]
  );

  const NAV_ITEMS = useMemo(
    () => [
      {
        title: "Home",
        href: "/",
      },
      {
        title: "Companies pricing",
        href: "/pricing",
      },
    ],
    [headerTranslation]
  );

  const SUB_NAV_ITEMS = useMemo(
    () => [
      {
        title: aboutTranslation["breadcrumb"]["page"],
        href: "/about",
      },
      {
        title: contactTranslation["breadcrumb"]["title"],
        href: "/contact",
      },
      {
        title: headerTranslation["blog-text"],
        href: "/blog",
      },
    ],
    [headerTranslation]
  );

  const CANDIDATE_NAV = useMemo(
    () => [
      {
        title: "Jobs",
        href: "/jobs",
      },
    ],
    [headerTranslation]
  );

  const mergeUniqueNavItems = (...arrays: any[]) => {
    const map = new Map();
    arrays.flat().forEach(item => {
      if (!map.has(item.href)) {
        map.set(item.href, item);
      }
    });
    return Array.from(map.values());
  };

  const navItems = useMemo(() => {
    if (session?.user?.role === "EMPLOYER") {
      return isMobile
        ? mergeUniqueNavItems(EMPLOYER_NAV_ITEMS, NAV_ITEMS, SUB_NAV_ITEMS)
        : EMPLOYER_NAV_ITEMS;
    } else if (session?.user?.role === "CANDIDATE") {
      return isMobile ? mergeUniqueNavItems(CANDIDATE_NAV, NAV_ITEMS) : CANDIDATE_NAV;
    } else {
      return isMobile
        ? mergeUniqueNavItems(CANDIDATE_NAV, NAV_ITEMS, SUB_NAV_ITEMS)
        : mergeUniqueNavItems(CANDIDATE_NAV, NAV_ITEMS);
    }
  }, [
    CANDIDATE_NAV,
    EMPLOYER_NAV_ITEMS,
    NAV_ITEMS,
    session?.user?.role,
    isMobile,
  ]);


  const toggleMenu = () => {
    if (menuOpen) {
      setMenuOpen(false);
    } else {
      setMenuOpen(true);
    }

    return () => { };
  };

  useEffect(() => {
    setMenuOpen(false);
  }, [pathname]);

  if (
    pathname.includes("forgot") ||
    pathname.includes("register") ||
    pathname.includes("login") ||
    pathname.includes("verify") ||
    pathname.includes("thank-you")
  ) {
    return null;
  }

  return (
    pathname !== "/create-profile/location" &&
    pathname !== "/create-profile/question" && (
      <>
        {pathname !== "/" ? (
          <div
            className={cn(
              subscription?.status === "EXPIRED"
                ? "h-[152px] sm:h-[142px]"
                : "h-20 sm:h-[90px]",
              "w-full"
            )}
          ></div>
        ) : null}
        <header
          className={cn(
            "bg-dark-2 flex flex-col w-full fixed top-0 left-0 right-0 z-50 shadow",
            subscription?.status === "EXPIRED"
              ? "h-[152px] sm:h-[142px]"
              : "h-20 sm:h-[90px]",
            pathname === "/" && isHeaderTransparent
              ? "bg-dark-2 sm:bg-transparent shadow-none sm:absolute"
              : "",
            pathname === "/" && !isHeaderTransparent ? "sm:fixed" : "",
            menuOpen ? "bg-dark-2 fixed shadow" : ""
          )}
        >
          {subscription?.status === "EXPIRED" ? (
            <div>
              <div className="ltr:border-l-4 rtl:border-r-4 border-yellow-400 bg-yellow-50 p-4">
                <div className="flex gap-3">
                  <div className="flex-shrink-0">
                    <ExclamationTriangleIcon
                      className="h-5 w-5 text-yellow-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="">
                    <p className="text-sm text-yellow-700">
                      {headerTranslation?.["subscription-expired-text-1"]}{" "}
                      <Link
                        href="/pricing"
                        className="font-medium text-yellow-700 underline hover:text-yellow-600"
                      >
                        {headerTranslation?.["subscription-expired-text-link"]}
                      </Link>{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ) : null}

          <div className="flex-1 flex items-center">
            <div className="lg-container">
              <div className="flex gap-5 justify-between lg:justify-start items-center xl:justify-between">
                <div className="flex gap-8 items-center">
                  <Link href="/">
                    <Image
                      src={Logo}
                      width={176}
                      height={72}
                      alt="WazifaMe Logo"
                      className="inline-block rounded w-[148px] h-[52px] object-contain object-left"
                      loading="lazy"
                    />
                  </Link>
                </div>
                <nav className="hidden lg:block mr-auto pl-4 xl:pl-0 xl:mr-0 left-1/2">
                  <ul className="flex gap-3 xl:gap-5 items-center">
                    {navItems.map((item, index) => (
                      <li key={index}>
                        <NavLink href={item.href}>{item.title}</NavLink>
                      </li>
                    ))}

                    <div className="relative group">
                      <button
                        className="flex flex-row items-center justify-center gap-[5px] text-[#fff] text-[15px]"
                      >
                        More <ChevronDownIcon />
                      </button>
                      <div
                        className='rounded-[8px] mt-[20px] absolute top-full left-0 bg-dark-2 overflow-hidden transition-all duration-300 opacity-0 invisible group-hover:opacity-100 group-hover:visible'>
                        <ul className="flex flex-col items-start gap-2 p-4">
                          {SUB_NAV_ITEMS.map((item, index) => (
                            <li key={index}>
                              <NavLink href={item.href}>
                                {item.title}
                              </NavLink>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>

                    {pathname !== "/" && session?.user?.role !== "EMPLOYER" && (
                      <NavSearch placeholder="Search for..." />
                    )}
                  </ul>
                </nav>
                <div className="flex items-center gap-3 sm:gap-5">
                  {session?.user?.id ? (
                    <div className="hidden md:flex items-center gap-3">
                      <ChatNotification session={session} />
                      <Avatar
                        subscription={subscription}
                        plan={plan}
                        name={
                          user ? `${user?.name ?? ""}` : session.user.name || ""
                        }
                        email={session.user.email || ""}
                        role={session.user.role}
                        userId={session.user.id}
                        profileImage={session?.user?.image}
                      />
                    </div>
                  ) : (
                    <div className="hidden gap-2.5 xl:gap-3.5 md:flex">
                      <Button
                        asChild
                        variant="secondary"
                        className="w-[134px] h-[40px] font-normal rounded-[38px] text-[#fff]"
                      >
                        <Link href="/login">Login / Register</Link>
                      </Button>
                    </div>
                  )}
                  {isMobile ? (
                    <button
                      onClick={() => toggleMenu()}
                      className="flex flex-row items-center justify-center text-[#fff]"
                      aria-label={menuOpen ? "Close menu" : "Open menu"}
                      title={menuOpen ? "Close menu" : "Open menu"}
                    >
                      {menuOpen ? (
                        <XMarkIcon className="w-[40px]" />
                      ) : (
                        <RightBarsIcon className="w-[40px]" />
                      )}
                    </button>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </header>

        {menuOpen ? (
          <div
            className={cn(
              "duration-300 flex p-6 pl-0 flex-col bg-dark-2 border-t border-dark-4 z-40 lg:hidden",
              subscription?.status === "EXPIRED"
                ? "h-[calc(100vh-152px)] sm:h-[calc(100vh-142px)] w-full fixed top-[152px] sm:top-[142px]"
                : "h-[calc(100vh-80px)] sm:h-[calc(100vh-90px)] w-full fixed top-20 sm:top-[90px]"
            )}
          >
            <div className="mb-[20px]">
              {session?.user?.id ? (
                <div className="flex justify-between items-center">
                  <div className="relative w-full bg-[#FFFFFF14] rounded-[50px] flex flex-row items-center p-[5px] justify-between">
                    <Avatar
                      subscription={subscription}
                      plan={plan}
                      name={session.user.name || ""}
                      email={session.user.email || ""}
                      role={session.user.role}
                      userId={session.user.id}
                      profileImage={session?.user?.image}
                    />
                    <ChevronRightIcon className="-z-10 absolute top-[50%] right-[5px] w-[30px] text-[#fff] translate-y-[-50%]" />
                  </div>
                  <Button
                    onClick={() => signOut()}
                    className="w-[50px] h-[50px] !bg-[#FFFFFF14] !rounded-full !ml-[10px]"
                    variant="gray"
                  >
                    <MdLogout className="text-base" />
                  </Button>
                </div>
              ) : (
                <div className="w-full flex flex-row items-center justify-center">
                  <Button
                    asChild
                    variant="secondary"
                    className="w-[225px] h-[40px] font-normal rounded-[38px] text-[#fff]"
                  >
                    <Link href="/login">Login / Register</Link>
                  </Button>
                </div>
              )}
            </div>

            <nav className="mt-[20px] flex-1 text-center flex items-center overflow-auto mb-4">
              <ul className="w-full h-full space-y-6 md:space-y-5">
                {navItems.map((item) => {
                  return (
                    <li
                      key={item.title}
                      className={cn(
                        pathname === item.href
                          ? "border-l border-[#4DBFDA] text-[#4DBFDA]"
                          : "border-l border-transparent text-[#fff]",
                        "duration-300 text-[18px] text-left pl-6"
                      )}
                    >
                      <NavLink
                        href={item?.href}
                        className={cn(
                          pathname === item.href
                            ? "text-[#4DBFDA]"
                            : "text-[#fff]",
                          "duration-300 text-[18px] p-0 block"
                        )}
                      >
                        {item?.title}
                      </NavLink>
                    </li>
                  );
                })}
              </ul>
            </nav>
          </div>
        ) : null}
      </>
    )
  );
};

export default HeaderClient;
